import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  ref: "echartsRef",
  style: {
    "height": "500px",
    "width": "1800px"
  }
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, null, 512);
}