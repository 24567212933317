import * as echarts from 'echarts'; // import echarts from '../../utils/Echarts'

export default {
  name: 'EChartsComponent',
  props: {
    option: {
      type: Object,
      required: true
    },
    width: {
      type: String,
      default: '600px'
    },
    height: {
      type: String,
      default: '400px'
    }
  },

  data() {
    return {
      chartInstance: null
    };
  },

  watch: {
    option: {
      handler(newOption) {
        if (this.chartInstance) {
          this.chartInstance.setOption(newOption);
        }
      },

      deep: true
    }
  },

  mounted() {
    this.chartInstance = echarts.init(this.$refs.echartsContainer);
    this.chartInstance.setOption(this.option);
    window.addEventListener('resize', this.resiseChart);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.resiseChart);

    if (this.chartInstance) {
      this.chartInstance.dispose();
    }
  },

  methods: {
    resiseChart() {
      this.chartInstance.resize();
    }

  }
};